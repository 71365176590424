<template>
    <div class="container politics">
        <h2>Оплата банковской картой онлайн</h2>
        <p>На нашем сайте вы можете оплатить товар банковской картой Visa, MasterCard или Мир. </p>
        <p>После подтверждения выбранного товара откроется защищенное окно со страницей платёжного сервис провайдера ArsenalPay, где Вам необходимо ввести данные Вашей банковской карты. </p>
        <p>Для дополнительной аутентификации держателя карты используется протокол 3D Secure. Если Ваш банк поддерживает данную технологию, Вы будете перенаправлены на его сервер для дополнительной идентификации. Информацию о правилах и методах дополнительной идентификации уточняйте, пожалуйста, в банке, выдавшем Вам банковскую карту.</p>

        <h3>Гарантии безопасности онлайн платежей</h3>
        <p>Процессинговый центр ArsenalPay защищает и обрабатывает данные Вашей банковской карты по максимальному международному стандарту безопасности — Payment Card Industry Data Security Standard 3.2 level 1 (сокращенно PCI DSS). Этот стандарт разработан платёжными системами Visa и MasterCard. Каждый год сертифицированные аудиторы проверяют, соответствует ли процессинговый центр всем требованиям и после этого выдают сертификат.</p>
        <p>Передача информации в платёжный шлюз происходит с применением технологии шифрования TLS. Дальнейшая передача информации происходит по закрытым банковским сетям, имеющим наивысший уровень надежности. ArsenalPay не передает данные Вашей карты нам и иным третьим лицам. Для дополнительной аутентификации держателя карты используется протокол 3D Secure.</p>
        <p>Предоставляемая Вами персональная информация (имя, адрес, телефон, email, номер кредитной карты) является конфиденциальной и не подлежит разглашению. Данные Вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем сервере.</p>
        <p>В случае, если у Вас есть вопросы по совершенному платежу, Вы можете обратиться в службу поддержки клиентов по электронной почте pay@arsenalpay.ru</p>
    </div>    
</template>

<style>
    
</style>